
<template>
  <div class="container-fluid px-4">
    <div class="row">
      <div class="col-12 pl-0">
        <div class="row mb-3 ml-0">
          <ul class="tab-group mb-0">
            <li class="nav-item" v-if="isAllowed('vpi-status.introduction-menu') || user.is_vessel === true">
              <router-link class="nav-link" :to="{ name: 'VPIIntroduction'}">
                <span>Introduction</span>
              </router-link>
            </li>
            <li class="nav-item" v-if="isAllowed('vpi-status.graph-menu') || user.is_vessel === true">
              <router-link class="nav-link" :to="{ name : 'VPIGraph'}">
                <span>Graph</span>
              </router-link>
            </li>
            <li class="nav-item" v-if="isAllowed('vpi-status.vpi.menu') || user.is_vessel === true">
              <router-link class="nav-link" :to="{ name : 'VPI'}">
                <span>VPI</span>
              </router-link>
            </li>
          </ul>
          <div class="col-12 pl-0">
            <router-view/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {permissionMixin} from "@/mixins/permissionMixins";
import {mapGetters} from "vuex";

export default {
  name: "VPIMain",
  mixins : [permissionMixin],
  computed : {
    ...mapGetters([
      'user'
    ])
  }
}
</script>

<style scoped lang="scss">
.tab-group {
  display: flex;
  width: 100%;
}

.tab-group > li {
  margin: .5rem 0;
  border-radius: 5px;
  list-style-type: none;

  &:hover, &:hover * {
    background-color: white;
    color: #d3313c;
  }
}

.e-btn-grp > li {
  list-style: none;
  font-size: 13px;
  padding: 4px;
  border-radius: 3px;
  color: #d3313c;
  cursor: pointer;

  &:hover {
    background-color: #fbeaec;
  }

  * {
    color: #d3313c;
  }
}

#ModelNav {
  padding: 0;

  li > a {
    padding: .5rem 1rem;
    color: #435464;
  }

  li:hover a {
    border-bottom: 2px solid #d3313c;
    color: #d3313c;
  }
}

.router-link-active {
  border-bottom: 2px solid #d3313c;
  color: #d3313c;

  * {
    color: #d3313c;
  }
}
</style>



